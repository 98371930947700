import useEmblaCarousel from 'embla-carousel-react'
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import {
  Pause,
  DollarSign,
  Globe,
  Tag,
  Zap,
  Play,
  Lock,
  HelpCircle,
  BookOpen,
  MessageCircle,
} from 'react-feather';

import { Button } from '@components/common/Button';
import ConnectCard from '@components/common/ConnectCard';
import MarketCard from '@components/common/MarketCard';
import ValueCard from '@components/common/ValueCard';
import { TopNav } from '@components/layouts/TopNav';
import { SVGIconThemed } from '@components/SVGIcon/SVGIconThemed';
import { MARKETS_CARDS, CONNECT_CARDS, CAROUSEL_ITEMS, VALUE_CARDS } from '@helpers/cards';
import useMediaQuery from '@hooks/useMediaQuery';
import { colors } from '@theme/colors';
import { ThemedText } from '@theme/text';

const isTicketsEnabled = process.env.ENABLE_TICKETS === 'true';

export const Landing: React.FC = () => {
  /*
   * Contexts
   */
  const currentDeviceSize = useMediaQuery();
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true })
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isHovering, setIsHovering] = useState(false);
  const [isTabVisible, setIsTabVisible] = useState(true);
  const bodyContainerRef = useRef<HTMLDivElement>(null);
  const connectContainerRef = useRef<HTMLDivElement>(null);

  /*
   * Effects
   */
  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', () => {
        setCurrentSlide(emblaApi.selectedScrollSnap())
      })
    }
  }, [emblaApi, isPlaying])

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const resetInterval = () => {
      if (intervalId) clearInterval(intervalId);
      if (isPlaying && emblaApi && !isHovering && isTabVisible) {
        intervalId = setInterval(() => {
          emblaApi.scrollNext();
        }, 5000);
      }
    };

    if (emblaApi) {
      emblaApi.on('select', resetInterval);
    }

    resetInterval();

    return () => {
      if (intervalId) clearInterval(intervalId);
      if (emblaApi) {
        emblaApi.off('select', resetInterval);
      }
    };
  }, [isPlaying, emblaApi, isHovering, isTabVisible]);

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  /*
   * Handlers
   */
  const handleJumpToSlide = (index: number) => {
    emblaApi?.scrollTo(index)
    setCurrentSlide(index)
    if (isPlaying) {
      setIsPlaying(false);
      setTimeout(() => setIsPlaying(true), 100);
    }
  }

  const handleJumpToMedia = (url: string) => {
    window.open(url, '_blank');
  };

  const handleContactClick = () => {
    connectContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  /*
   * Helpers
   */
  const getValueIconForIndex = (index: number, size: number = 64) => {
    switch (index) {
      case 0:
        return <StyledZap size={size} />;
      case 1:
        return <StyledDollarSign size={size} />;
      case 2:
      default:
        return <StyledLock size={size} />;
    }
  };

  const getMarketIconForIndex = (index: number, size: number = 64) => {
    switch (index) {
      case 0:
        return <DollarSign size={size} />;
      case 1:
        return <Tag size={size} />;
      case 2:
      default:
        return <Globe size={size} />;
    }
  };

  const getConnectIconForIndex = (index: number, size: number = 24) => {
    switch (index) {
      case 0:
        return <MessageCircle size={size} />;
      case 1:
        return <BookOpen size={size} />;
      case 2:
      default:
        return <HelpCircle size={size} />;
    }
  };

  return (
    <PageWrapper $isMobile={currentDeviceSize === 'tablet' || currentDeviceSize === 'mobile'}>
      <TopNav onContactClick={handleContactClick} />
      <HeroContainer>
        <HeroTextContainer>
          <ThemedText.Hero>
            <div style={{ display: 'flex', justifyContent: 'center', fontSize: currentDeviceSize === 'mobile' ? 48 : 54, fontWeight: 600 }}>
              The Authentic Digital Goods Marketplace
            </div>
          </ThemedText.Hero>
        </HeroTextContainer>

        <CarouselContainer ref={emblaRef}>
          <CarouselSlideContainer>
            {CAROUSEL_ITEMS.map((item, index) => (
              <CarouselSlide key={index}>
                <CarouselCardWrapper>
                  <CarouselCardContainer>
                    <CarouselCardTextContent>
                      <CarouselCardTitle>{item.title}</CarouselCardTitle>
                      <CarouselCardDescription>{item.description}</CarouselCardDescription>
                      <ButtonContainer
                        onMouseEnter={() => setIsHovering(true)}
                        onMouseLeave={() => setIsHovering(false)}
                      >
                        <Button
                          bgColor={item.buttonColor}
                          textColor={item.buttonTextColor}
                          bgColorHover={item.buttonHoverColor}
                          shadow={true}
                          disabled={item.disabled}
                          onClick={() => handleJumpToMedia(item.buttonLink)}
                        >{item.disabled ? 'Coming Soon' : item.buttonText}</Button>
                      </ButtonContainer>
                    </CarouselCardTextContent>
                    
                    <CarouselIconGrid>
                      {item.icons.map((icon, index) => (
                        <CarouselIconWrapper
                          key={index}
                          disabled={item.disabled}
                          onMouseEnter={() => setIsHovering(true)}
                          onMouseLeave={() => setIsHovering(false)}
                          onClick={() => !item.disabled && handleJumpToMedia(item.buttonLink)}
                        >
                          <StyledCarouselIcon src={icon} />
                        </CarouselIconWrapper>
                      ))}
                    </CarouselIconGrid>
                  </CarouselCardContainer>
                </CarouselCardWrapper>
                <CarouselImageContainer $background={item.backgroundImage} />
              </CarouselSlide>
            ))}
          </CarouselSlideContainer>

          <CarouselControls $isMobile={currentDeviceSize === 'tablet' || currentDeviceSize === 'mobile'}>
            <ControlButton onClick={togglePlayPause}>
              {isPlaying ? <Pause size={24} /> : <Play size={24} />}
            </ControlButton>
          </CarouselControls>
          <CarouselDots>
            {CAROUSEL_ITEMS.map((_, index) => (
              <Dot 
                key={index} 
                $active={index === currentSlide} 
                onClick={() => handleJumpToSlide(index)}
              />
            ))}
          </CarouselDots>
          <LearnMoreContainer
            $isMobile={currentDeviceSize === 'tablet' || currentDeviceSize === 'mobile'}
            onClick={() => {
              bodyContainerRef?.current?.scrollIntoView({ behavior: 'smooth' })
            }}
          >
            Learn more
            <LearnMoreIcon icon="arrow-down" darkOpacity={0} />
          </LearnMoreContainer>
        </CarouselContainer>
      </HeroContainer>
      

      <BodyContainer ref={bodyContainerRef}>
        <ValueContainer paddingHorizontal={currentDeviceSize === 'mobile' ? 0 : 16}>
          <ValueContent>
            <ThemedText.HeadlineLarge style={{ fontSize: currentDeviceSize === 'mobile' ? 32 : 40 }}>
              The User-Driven Marketplace
            </ThemedText.HeadlineLarge>
            <ValueContentBottom>
              <ValueText>
                We're improving existing web-based marketplaces by eliminating third-party oversight. Through advanced verification, escrow, and settlement tools, we offer unparalleled guarantees for digital item exchange at a fraction of the cost.
              </ValueText>
              <ValueText>
                Our infrastructure allows sellers to create proofs of ownership for digital items across some of the largest apps in the world like Venmo, Ticketmaster, and Namecheap. These proofs are required to list items on our marketplace, giving buyers confidence they are paying for legitimate goods.
              </ValueText>
              <ValueText>
                Through our cryptographic proofs of transfer, sellers prove they have transferred the right items to the buyer before receiving payment. This ensures that buyers are protected, and sellers get immediate access to their funds once the transfer is complete.
              </ValueText>
              <ButtonContainer>
                <Button
                  onClick={() => handleJumpToMedia('https://docs.zkp2p.xyz')}
                  border={true}
                >
                  View Docs ↗
                </Button>
              </ButtonContainer>
            </ValueContentBottom>
          </ValueContent>
          <ValueCardGrid>
            {VALUE_CARDS.map((card, index) => (
              <ValueCard
                key={index}
                {...card}
                icon={getValueIconForIndex(index)}
              />
            ))}
          </ValueCardGrid>
        </ValueContainer>

        <MarketCardContainer paddingHorizontal={currentDeviceSize === 'mobile' ? 0 : 16}>
          <ThemedText.HeadlineLarge>
            Explore our markets
          </ThemedText.HeadlineLarge>
          <MarketCardGrid cols={currentDeviceSize === 'mobile' ? 1 : MARKETS_CARDS.length}>
            {MARKETS_CARDS.map((card, index) => (
              <MarketCard
                {...card}
                key={card.title}
                icon={getMarketIconForIndex(index, 24)}
              />
            ))}
          </MarketCardGrid>
        </MarketCardContainer>

        <ConnectContainer paddingHorizontal={currentDeviceSize === 'mobile' ? 0 : 16} ref={connectContainerRef}>
          <ThemedText.HeadlineLarge>
            Connect with us
          </ThemedText.HeadlineLarge>
          <ConnectCardGrid>
            {CONNECT_CARDS.map((card, index) => (
              <ConnectCard
                {...card}
                key={index}
                icon={getConnectIconForIndex(index, 24)}
                backgroundColor={colors.white}
              />
            ))}
          </ConnectCardGrid>
        </ConnectContainer>
      </BodyContainer>
      
      <FooterWrapper>
        <FooterContainer>
          <FooterBottomSection>
            <FooterLinks>
              <FooterLinkGroup>
                <FooterLinkTitle>Apps</FooterLinkTitle>
                <FooterLink onClick={() => handleJumpToMedia('https://onramp.zkp2p.xyz')}>Ramp</FooterLink>
                {isTicketsEnabled && <FooterLink onClick={() => handleJumpToMedia('https://tickets.zkp2p.xyz')}>Tickets</FooterLink>}
                <FooterLink onClick={() => handleJumpToMedia('https://domains.zkp2p.xyz')}>Domains</FooterLink>
              </FooterLinkGroup>
              <FooterLinkGroup>
                <FooterLinkTitle>Resources</FooterLinkTitle>
                <FooterLink onClick={() => handleJumpToMedia('https://docs.zkp2p.xyz')}>Documentation</FooterLink>
                <FooterLink onClick={() => handleJumpToMedia('https://github.com/zkp2p')}>Github</FooterLink>
              </FooterLinkGroup>
              <FooterLinkGroup>
                <FooterLinkTitle>Contact</FooterLinkTitle>
                <FooterLink onClick={() => handleJumpToMedia('mailto:team@zkp2p.xyz')}>Contact us</FooterLink>
              </FooterLinkGroup>
            </FooterLinks>
          </FooterBottomSection>
        </FooterContainer>
        <FooterBottomBar>
          <FooterLeft>
            <FooterCopyright>© 2024 - P2P Labs Inc.</FooterCopyright>
          </FooterLeft>

          <StyledSocialIcons>
            <StyledSocialIcon
              icon={'twitter'}
              darkOpacity={0}
              onClick={() => handleJumpToMedia('https://twitter.com/zkp2p')}
            />

            <StyledSocialIcon
              icon={'github'}
              darkOpacity={0}
              onClick={() => handleJumpToMedia('https://github.com/zkp2p')}
            />

            <StyledSocialIcon
              icon={'telegram'}
              darkOpacity={0}
              onClick={() => handleJumpToMedia('https://t.me/+XDj9FNnW-xs5ODNl')}
            />
          </StyledSocialIcons>
        </FooterBottomBar>
      </FooterWrapper>
    </PageWrapper>
  );
};

const PageWrapper = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
`;

const HeroContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

const HeroTextContainer = styled.div`
  max-width: 640px;
  position: absolute;
  z-index: 1;
  color: ${colors.white};
  left: 5rem;
  top: 18rem;

  @media (max-width: 768px) {
    left: 1rem;
    top: 14rem;
    padding: 0 0.5rem;
  }
`;

const LearnMoreContainer = styled.div<{ $isMobile: boolean }>`
  position: absolute;
  bottom: 48px;
  left: 50%;
  transform: translateX(-50%);
  color: ${colors.white};
  cursor: pointer;
  font-size: 20px;
  font-weight: 535;
  pointer-events: auto;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const LearnMoreIcon = styled(SVGIconThemed)`
  width: 20px;
  height: 20px;
`;

const CarouselContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  min-height: 850px;
`;

const CarouselSlideContainer = styled.div`
  display: flex;
  height: 100%;
`;

const CarouselSlide = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.3fr;
  justify-content: center;
  align-items: center;
  flex: 0 0 100%;
  background-color: #1D1F22;
`;

const CarouselImageContainer = styled.div<{ $background: string }>`
  background-image: ${props => props.$background};
  background-size: cover;
  background-position: left;
  width: 100%;
  height: 100%;
`;

const CarouselCardContainer = styled.div`
  max-width: 480px;
  display: flex;
  align-items: center;
  text-align: left;
  flex-direction: row;
  justify-content: center;
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  backdrop-filter: blur(10px);
  box-shadow: 
    0 8px 40px rgba(0, 0, 0, 0.2),
    0 15px 30px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.3);

  @media (max-width: 768px) {
    gap: 1rem;
    padding: 1rem;
  }
`;

const CarouselCardTextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const CarouselIconGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1rem;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const CarouselIconWrapper = styled.div<{ disabled: boolean }>`
  padding: 0.5rem;
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};

  &:hover {
    scale: ${({ disabled }) => disabled ? 1 : 1.05};
  }

  @media (max-width: 768px) {
    padding: 0.25rem;
  }
`;

const StyledCarouselIcon = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 12px;

  @media (max-width: 768px) {
    width: 48px;
    height: 48px;
  }
`;

const CarouselCardTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${colors.white};

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const CarouselCardDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.2;
  color: ${colors.white};

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const CarouselCardWrapper = styled.div`
  padding: 5rem;
  margin-top: 20rem;
  flex-direction: row;
  align-items: flex-start;

  @media (max-width: 768px) {
    padding: 2rem;
    margin-top: 20rem;
  }
`;

const ButtonContainer = styled.div`
  width: 186px;
  display: grid;
  margin-top: 1rem;
`;

const CarouselControls = styled.div<{$isMobile: boolean}>`
  position: absolute;
  bottom: 48px;
  right: 60px;
  display: ${({ $isMobile }) => $isMobile ? 'none' : 'flex'};
  gap: 10px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ControlButton = styled.button`
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
`;

const CarouselDots = styled.div`
  position: absolute;
  bottom: 48px;
  left: 5rem;
  display: flex;
  gap: 10px;

  @media (max-width: 768px) {
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  }
`;

const Dot = styled.div<{ $active: boolean }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props => props.$active ? 'white' : 'rgba(255, 255, 255, 0.5)'};
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.$active ? 'white' : 'rgba(255, 255, 255, 0.8)'};
  }
`;

const StyledFooterLink = styled.a`
  color: inherit;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const BodyContainer = styled.div`
  min-height: 535px;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
  padding: 3rem 1rem 1rem 1rem;
  max-width: calc(100vw - 10rem);

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const ValueContainer = styled.div<{ paddingHorizontal: number }>`
  display: flex;
  gap: 2rem;
  min-height: 500px;
  padding: 4rem 0 0;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ValueContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

const ValueContentBottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: .5rem;
`;

const ValueText = styled.p`
  color: ${colors.grayText};
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: .4rem;
`;

const ValueCardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  flex: 1;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const StyledZap = styled(Zap)`
  width: 20px;
  height: 20px;
  color: ${colors.grayText};
`;

const StyledDollarSign = styled(DollarSign)`
  width: 20px;
  height: 20px;
  color: ${colors.grayText};
`;

const StyledLock = styled(Lock)`
  width: 20px;
  height: 20px;
  color: ${colors.grayText};
`;

const MarketCardContainer = styled.div<{ paddingHorizontal: number }>`
  display: grid;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
  padding: 4rem 0 0;
  width: 100%;
`;

const MarketCardGrid = styled.div<{ cols: number }>`
  display: grid;
  gap: 32px;
  padding: 24px 0 0;
  scroll-margin: 10px 0 0;

  grid-template-columns: ${({ cols }) => `repeat(${cols}, 1fr)`};
  width: 100%;
`;

const ConnectContainer = styled.div<{ paddingHorizontal: number }>`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: flex-start;
  margin-top: 2rem;
  padding: 4rem 0 0;
  width: 100%;
`;

const ConnectCardGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 2rem;
  margin-top: 2rem;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FooterWrapper = styled.div`
  width: 90vw;
  padding-top: 4rem;

  @media (max-width: 768px) {
    padding-bottom: 5rem;
  }
`;

const FooterContainer = styled.div`
  margin: 0 auto;
  padding: 3rem 0rem;
`;

const FooterBottomSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 4rem;
`;

const StyledSocialIcons = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 768px) {
    align-self: flex-start;
  }
`;

const StyledSocialIcon = styled(SVGIconThemed)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.6;
  }
`;

const FooterLinks = styled.div`
  display: flex;
  gap: 5rem;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: 768px) {
    gap: 1.5rem;
  }
`;

const FooterLinkGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const FooterLinkTitle = styled.div`
  font-size: 1.2rem;
  padding-bottom: .75rem;
`;

const FooterLink = styled.a`
  color: ${colors.grayText};
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const FooterBottomBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0;
  border-top: 1px solid ${colors.defaultBorderColor};

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;

const FooterLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;

const FooterCopyright = styled.div`
  font-size: 1rem;
`;

const FooterPolicies = styled.div`
  display: flex;
  gap: 1rem;
  font-size: 1rem;
`;

export default Landing;
