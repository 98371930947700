import { useRef, useReducer } from 'react';
import { MoreHorizontal } from 'react-feather';
import styled from 'styled-components';

import { SVGIconThemed } from '@components/common/SVGIconThemed';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { Z_INDEX } from '@theme/zIndex';
import useMediaQuery from '@hooks/useMediaQuery';


export const MenuDropdown = () => {
  const [isOpen, toggleOpen] = useReducer((s) => !s, false)

  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, isOpen ? toggleOpen : undefined)
  const isMobile = useMediaQuery() === 'mobile';

  /*
   * Handler
   */

  const jumpToMedia = (url: string) => {
    window.open(url, '_blank');
  };

  /*
   * Component
   */

  return (
    <Wrapper isMobile={isMobile} ref={ref}>
      <NavButton onClick={toggleOpen}>
        <StyledMoreHorizontal />
      </NavButton>

      {isOpen && (
        <NavDropdown isMobile={isMobile}>
          <NavDropdownItem
            href="https://dune.com/zkp2p/zkp2p"
            target="_blank"
            rel="noopener noreferrer">
              <ThemedText.LabelSmall textAlign="left">
                Analytics ↗
              </ThemedText.LabelSmall>
          </NavDropdownItem>

          <NavDropdownItem
            href="https://docs.zkp2p.xyz/zkp2p/"
            target="_blank"
            rel="noopener noreferrer">
              <ThemedText.LabelSmall textAlign="left">
                Documentation ↗
              </ThemedText.LabelSmall>
          </NavDropdownItem>
          
          <NavDropdownItem
            href="https://chromewebstore.google.com/detail/zkp2p-extension/ijpgccednehjpeclfcllnjjcmiohdjih"
            target="_blank"
            rel="noopener noreferrer">
              <ThemedText.LabelSmall textAlign="left">
                Browser Extension ↗
              </ThemedText.LabelSmall>
          </NavDropdownItem>

          <IconRow>
            <Icon
              icon={'twitter'}
              onClick={() => jumpToMedia('https://twitter.com/zkp2p')}
            />

            <Icon
              icon={'github'}
              onClick={() => jumpToMedia('https://github.com/zkp2p')}
            />

            <Icon
              icon={'telegram'}
              onClick={() => jumpToMedia('https://t.me/+XDj9FNnW-xs5ODNl')}
            />
          </IconRow>
        </NavDropdown>
      )}
    </Wrapper>
  )
};

const Wrapper = styled.div<{isMobile?: boolean}>`
  display: flex;
  ${({ isMobile }) => isMobile ? '' : 'flex-direction: column'};
  ${({ isMobile }) => isMobile ? '' : 'align-items: flex-start'};
  position: relative;
`;

const StyledMoreHorizontal = styled(MoreHorizontal)`
  width: 24px;
  height: 24px;
`;

const NavButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 8px;
`;

const NavDropdown = styled.div<{isMobile?: boolean}>`
  display: flex;
  flex-direction: column;
  width: 176px;
  border-radius: 12px;
  border: 1px solid ${colors.defaultBorderColor};
  padding: 1.75rem 1.5rem;
  background: #FAF9F6;
  z-index: ${Z_INDEX.menu_dropdown};
  gap: 0.75rem;
  color: ${colors.darkText};

  position: absolute;
  ${({ isMobile }) => isMobile ? `
    bottom: calc(100% + 28px);
    top: auto;
    right: -20px;
  ` : `
    top: calc(100% + 20px);
    right: 0;
  `}
`;

const NavDropdownItem = styled.a`
  color: inherit;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const IconRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 0.5rem;
  align-items: center;
`;

const Icon = styled(SVGIconThemed)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.6;
  }
`;
