import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { NavItem } from '@components/layouts/TopNav/NavItem';
import { Button } from '@components/common/Button';
import useMediaQuery from '@hooks/useMediaQuery';
import { colors } from '@theme/colors';

export const TopNav: React.FC<{ withoutLinks?: boolean, onContactClick?: () => void }> = ({ withoutLinks, onContactClick }) => {
  /*
   * Context
   */

  const currentDeviceSize = useMediaQuery();

  /*
   * State
   */

  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState<string>('Browse');

  /*
   * Hooks
   */

  useEffect(() => {
    const routeName = location.pathname.split('/')[1];
    setSelectedItem(routeName || 'Landing');
  }, [location]);

  /*
   * Component
   */
  
  if (currentDeviceSize === 'mobile') {
    return (
      <NavBar>
        {withoutLinks ? (
          <NavBarCentered>
            <Logo size={48} to='/' onClick={() => setSelectedItem('Landing')}>
              <img src={`${process.env.PUBLIC_URL}/logo512.png`} alt="logo" />
            </Logo>
          </NavBarCentered>
        ) : (
          <LogoAndNavItems>
            <LogoAndTitle to="/" onClick={() => setSelectedItem('Landing')}>
              <img src={`${process.env.PUBLIC_URL}/logo512.png`} alt="logo" />

              ZKP2P
            </LogoAndTitle>
          </LogoAndNavItems>
        )}
        
        <ContactMenuContainer>
          <Button height={40} bgColor={colors.white} onClick={onContactClick}>
            Contact Us
          </Button>
        </ContactMenuContainer>
      </NavBar>
    );
  } else {
    return (
      <NavBar>
        <LogoContainer>
          <LogoAndNavItems>
            <LogoAndTitle to="/" onClick={() => setSelectedItem('Landing')}>
              <img src={`${process.env.PUBLIC_URL}/logo512.png`} alt="logo" />

              ZKP2P
            </LogoAndTitle>
          </LogoAndNavItems>
        </LogoContainer>

        {!withoutLinks && (
          <NavItemsAndButton>
            {currentDeviceSize === 'laptop' && (
              <NavItem selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
            )}
            <Button
              height={40}
              bgColor={colors.white}
              bgColorHover="#E6E4E1"
              disabledColor="#F5F5F5"
              textColor={colors.black}
              shadow={false}
              border={true}
              onClick={onContactClick}
            >
              Contact Us
            </Button>
          </NavItemsAndButton>
        )}
      </NavBar>
    );
  }
};

const NavBarCentered = styled.div`
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: center;
`;

const NavBar = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 1.75rem 1.75rem 1.5rem; 
  color: white;

  @media (min-width: 600px) {
    padding: 28px 4rem;
  }
`;

const Logo = styled(Link)<{ size?: number }>`
  img {
    width: ${({ size }) => size || 32}px;
    height: ${({ size }) => size || 32}px;
    object-fit: cover;
  }
`;

const ContactMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const LogoAndNavItems = styled.div`
  display: flex;
  align-items: center;
  gap: 4rem;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const NavItemsAndButton = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;
`;

const LogoAndTitle = styled(Link)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${colors.white};
  font-size: 20px;
  font-style: italic;
  font-weight: 600;

  text-decoration: none;

  img {
    padding-bottom: 2px;
    width: 48px;
    height: 48px;
    object-fit: cover;
  }
`;